exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-afreshjs-tsx": () => import("./../../../src/pages/afreshjs.tsx" /* webpackChunkName: "component---src-pages-afreshjs-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lab-box-resizer-tsx": () => import("./../../../src/pages/lab/box-resizer.tsx" /* webpackChunkName: "component---src-pages-lab-box-resizer-tsx" */),
  "component---src-pages-lab-index-tsx": () => import("./../../../src/pages/lab/index.tsx" /* webpackChunkName: "component---src-pages-lab-index-tsx" */),
  "component---src-pages-pitfall-tsx": () => import("./../../../src/pages/pitfall.tsx" /* webpackChunkName: "component---src-pages-pitfall-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

